import Typesense from 'typesense'

let client = new Typesense.SearchClient({
  nodes: [
    {
      host: 'search.lucalabs.com',
      port: '443',
      protocol: 'https',
    },
  ],
  apiKey: 'RUXUlOTytkfU9pn4KGRM9RWbasGU9LRc',
  connectionTimeoutSeconds: 2,
})

let commonSearchParams = {
  query_by: 'title',
  per_page: 5,
}

function doSearch(query, collectionOrder) {
  // See https://typesense.org/docs/0.21.0/api/documents.html#federated-multi-search for example params

  let collectionParameters = collectionOrder.map((collection) => {
    return {
      q: query,
      collection: collection,
    }
  })

  let searchParameters = {
    searches: collectionParameters,
  }

  return client.multiSearch.perform(searchParameters, commonSearchParams)
}

window.autoComplete = function (collectionOrder) {
  return {
    activeSuggestion: null,
    inputValue: '',
    active: true,
    results: [],
    totalFound: 0,
    searching: false,
    query: '',
    collectionSettings: {
      help_articles: {
        readableName: 'Hjelp',
        pillColour: 'bg-indigo-100',
      },
      account_descriptions: {
        readableName: 'Konto',
        pillColour: 'bg-green-100',
      },
      dictionary: {
        readableName: 'Ordbok',
        pillColour: 'bg-pink-100',
      },
    },
    currentPage: { help_articles: 1, account_descriptions: 1, dictionary: 1 },
    updateResults: function (data) {
      this.query = data
      this.searching = true
      doSearch(this.query, collectionOrder).then((result) => {
        this.totalFound = result.results.reduce((accumulator, obj) => {
          return accumulator + obj.found
        }, 0)

        if (window.searchExclude !== undefined) {
          result.results.forEach((res) => {
            res.hits = res.hits.filter((hit) => !window.searchExclude.includes(hit.document.id))
          })
        }
        if (window.slugReplacement !== undefined) {
          result.results.forEach((res) => {
            res.hits.forEach((hit) => {
              hit.document.path = hit.document.path.replace('luca', window.slugReplacement)
              hit.document.title = hit.document.title.replace('luca', window.companyShortName)
              hit.document.title = hit.document.title.replace('Luca', window.companyShortName)
            })
          })
        }
        this.results = result.results
        this.searching = false
      })
    },
    fetchMore: function (collectionName) {
      let collection = this.results.find((collection) => collection.request_params.collection_name === collectionName)
      this.currentPage[collectionName]++

      let searchParameters = Object.assign(
        {
          q: collection.request_params.q,
          page: this.currentPage[collectionName],
        },
        commonSearchParams,
      )

      client
        .collections(collectionName)
        .documents()
        .search(searchParameters)
        .then((result) => {
          if (window.searchExclude !== undefined) {
            result.hits = result.hits.filter((hit) => !window.searchExclude.includes(hit.document.id))
          }
          collection.hits.push(...result.hits)
        })
    },
  }
}
